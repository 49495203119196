<script setup lang="ts">
import { getCardTypeByNumber } from '@eonx-com/payment-elements'
import { defineAsyncComponent, computed, onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { OrderResponse } from '/~/types/api'
import BatchPayeeTable from '/~/extensions/bank-file-upload/components/transactions/payee-table/payee-table.vue'
import { cardCode } from '/~/utils/cards'
import { formatDate } from '/~/utils/format/date'
import { formatPoints } from '/~/utils/points'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import PointsPanel from '/~/components/points/points-panel.v3.pure.vue'
import { Activity, useActivity } from '/~/composables/activity'
import { useAddresses } from '/~/composables/addresses'
import { useBatchOrderPayeesTable } from '/~/composables/batch-order'
import { FlowType } from '/~/composables/checkout/checkout-types'
import {
  usePaymentMethods,
  sortPaymentMethodsByType,
  PaymentMethodType,
} from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { useUser } from '/~/composables/user'
import PayidStatusDescription from '/~/templates/bill-payments-v1_5/views/payments/make/views/successful/components/payid-status-description.vue'
import PaymentSuccessfulV2Pure from '/~/templates/bill-payments-v1_5/views/payments/make/views/successful/payments-make-successful.pure.vue'
import TaxInvoice from '/~/views/activity/details/components/tax-invoice.vue'

// TODO: move to composition (and from src/templates/bill-payments/views/activity/details/components/activity-details-item.vue)

const ActivityDetailsPayid = defineAsyncComponent(
  () =>
    import(
      '/~/templates/bill-payments-v1_5/views/activity/components/activity-details-payid.vue'
    )
)

const props = withDefaults(
  defineProps<{
    id?: string
  }>(),
  {
    id: '',
  }
)
const { user } = useUser()
const router = useRouter()
const { routeState, getActivityItem, getFlowOrderItem } = useActivity()
const {
  calculatePointsEarnedForPayment,
  calculatePointsEarnedForPurchase,
  pointsLabel,
} = usePoints()
const { batchOrderPayeesTable } = useBatchOrderPayeesTable()
const { userAddress } = useAddresses()
const { hasPaymentMethodByType } = usePaymentMethods()

const loaded = ref(false)
const item = ref<Activity>()
const order = ref<OrderResponse>()

const flowType = computed(() => item.value?.flowType ?? FlowType.purchase)

const config = computed(() => {
  const config = {
    baseURL: eonx.hosts.api.v1_1,
  }

  return config
})

const tier = computed(
  () => order.value.metadata?.membershipName || user.value.membershipName
)

const hasPayIdPaymentMethod = computed(() =>
  hasPaymentMethodByType(order.value.paymentMethods, PaymentMethodType.payId)
)

function goBack() {
  router.replace({ name: 'activity', ...routeState.value })
}

function getOrder() {
  if (item.value) {
    return getFlowOrderItem(flowType.value, item.value.orderNumber)
  }

  return Promise.resolve(undefined)
}

async function getActivityDetails() {
  try {
    item.value = await getActivityItem(props.id, config.value)
    order.value = await getOrder()
  } catch (error) {
    console.error(error)
    goBack()
  } finally {
    loaded.value = true
  }
}

onBeforeMount(() => {
  getActivityDetails()
})

const pointsEarned = computed(() => {
  const pointsTransactions = order.value?.pointsTransactions || []
  const earnTransaction = pointsTransactions.filter((transaction: any) => {
    return transaction.type === 'earn'
  })
  const allPointsCompleted = earnTransaction.every(
    (transaction: any) => transaction.status === 'completed'
  )

  if (earnTransaction.length > 0) {
    return {
      points: earnTransaction.reduce(
        (pointsEarned: number, transaction: any) => {
          return pointsEarned + transaction.allocatedValue
        },
        0
      ),
      status: allPointsCompleted ? 'completed' : 'pending',
    }
  }

  if (item.value?.isTypePurchaseOrder && order.value?.items) {
    return {
      points: calculatePointsEarnedForPurchase(order.value.items),
      status: 'pending',
    }
  } else {
    return {
      points: calculatePointsEarnedForPayment(order.value?.subtotal ?? 0),
      status: 'pending',
    }
  }
})

const paymentMethods = computed(() => {
  if (item.value?.isTypePointsTransaction) {
    return []
  }

  // get existing payment methods
  const paymentMethods =
    [
      order.value?.scheduledPaymentMethods,
      order.value?.paymentMethods,
      (order.value as any)?.paymentSources,
      item.value?.paymentMethods,
    ].find((methods) => methods?.length) || []

  return sortPaymentMethodsByType(
    paymentMethods
      .map((method: any) => {
        let result

        if (method.type) {
          result = {
            ...method,
          }
        } else {
          result = {
            type: method,
            amount: paymentMethods.length === 1 ? item.value?.total : 0,
            brand: 'plain/creditcards',
          }
        }
        const issuingNetwork = getCardTypeByNumber(method.number)
        const brand = issuingNetwork
          ? cardCode({ brand: issuingNetwork?.type })
          : undefined

        if (brand) {
          result.brand = `billpay/payment-methods/${brand}`
        }

        switch (result.type) {
          case PaymentMethodType.payId:
            result.title = 'PayID'
            result.details = result.name
            break
          case PaymentMethodType.points:
            result.title = pointsLabel.value
            result.details = `${formatPoints(result.points)} points redeemed`
            break
          case PaymentMethodType.creditCard:
            result.title = result.name
            result.details = result.number
            break
          case PaymentMethodType.bankAccount:
            result.title = result.name
            result.bsb = method.bsb
            break
          case PaymentMethodType.couponCode:
            result.title = 'Coupon'
            result.details = method.number
            break
        }
        return result
      })
      .filter((i: any) => i.amount > 0)
  )
})

const isPaymentMethodPayId = computed(() =>
  paymentMethods.value.some((i) => i.type === PaymentMethodType.payId)
)

const showPointsPanel = computed(
  () => !item.value?.isStatusCancelled && !item.value?.isStatusFailed
)

const showPrintButton = computed(() => {
  if (isPaymentMethodPayId.value) {
    return Boolean(!item.value?.isTypeRefund)
  }

  return Boolean(
    !item.value?.isStatusCancelled &&
      (item.value?.isTypePurchaseOrder ||
        item.value?.isTypePaymentOrder ||
        item.value?.isTypeStatementOrder ||
        item.value?.isTypeBatchOrder)
  )
})

const payee = computed(() => ({
  ...(order.value as any)?.payee,
  ...item.value?.payee,
  reference: item.value?.reference,
  subtotal: item.value?.subtotal,
}))

const date = computed(() => {
  let value =
    item.value?.happenedAt ||
    (order.value as any)?.paidAt ||
    order.value?.completedAt

  return formatDate('daymonthyearfulltime', value)
})

const printContent = computed(() => {
  let address = {
    ...userAddress.value,
    companyName:
      (order.value as any)?.userStatementAccount?.name ||
      userAddress.value?.companyName,
    accountNumber: (order.value as any).userStatementAccount?.number,
    mobile: null,
    email: null,
  }

  if ((order.value as any)?.billingAddress) {
    address = { ...(order.value as any).billingAddress }
  } else if ((order.value as any)?.shippingAddress) {
    address = { ...(order.value as any).shippingAddress }
  }

  return Object.setPrototypeOf(
    {
      ...item.value,
      ...order.value,
      payees: (order.value as any)?.payees || [payee.value],
      address,
      date: date.value,
    },
    Object.getPrototypeOf(order.value || {})
  )
})

function print() {
  window.print()
}
</script>

<template>
  <div class="mx-auto flex w-full max-w-2xl">
    <base-loader v-if="!loaded" size="xl" fullwidth class="mt-40" />

    <div
      v-else-if="!order"
      class="w-full py-[30px] text-center text-2xl font-bold"
    >
      Order information not found

      <div class="mt-[30px]">
        <base-button full-width @click="$router.push({ name: 'activity' })">
          Back to Activity
        </base-button>
      </div>
    </div>

    <payment-successful-v2-pure
      v-else
      :flow-type="flowType"
      :order="order"
      :activity="item"
      :level="tier"
      :status="item.status"
      :payment-id="id"
      class="w-full"
      :view-button-enabled="false"
      :done-button-enabled="false"
      :print-button-enabled="showPrintButton"
      @back="goBack"
      @print="print"
      @done="goBack"
    >
      <template slot="payid-status-description">
        <payid-status-description
          v-if="hasPayIdPaymentMethod"
          class="m-6"
          :status="order.status"
          :sub-status="order.subStatus"
          :activity="item"
        />
      </template>
      <template v-if="item && showPointsPanel" slot="points-panel">
        <points-panel
          :points="pointsEarned.points"
          :status="pointsEarned.status"
          :level="tier"
          :border-x="false"
        />
      </template>
      <template
        v-if="isPaymentMethodPayId && order.isStatusAwaitingFunds"
        slot="pay-with-payid"
      >
        <activity-details-payid :order="order" :flow-type="item.flowType" />
      </template>

      <template v-if="item && item.isTypeBatchOrder" slot="batch-table">
        <batch-payee-table
          :pagination="batchOrderPayeesTable.pagination"
          :payees="batchOrderPayeesTable.transactionList.list"
          :tabs="batchOrderPayeesTable.tabs.list"
          :loading="batchOrderPayeesTable.loader.loading"
          class="py-6 text-eonx-neutral-800"
          @select-tab="batchOrderPayeesTable.setActiveTab($event)"
          @select-page="batchOrderPayeesTable.load($event)"
          @select-per-page="batchOrderPayeesTable.setPerPage(`${$event}`)"
        />
      </template>
    </payment-successful-v2-pure>

    <tax-invoice
      v-if="loaded && item"
      :is-type-purchase-order="item.isTypePurchaseOrder"
      :is-type-payment-order="item.isTypePaymentOrder"
      :is-type-cashback-reconciliation-feed="
        item.isTypeCashbackReconciliationFeed
      "
      :is-type-statement-order="item.isTypeStatementOrder"
      :is-type-batch-order="item.isTypeBatchOrder"
      :is-type-cash-transaction="item.isTypeCashTransaction"
      :content="printContent"
    />
  </div>
</template>
